export const environment = {
  PUBLIC_API_URL: 'https://localhost:5001/',
  gateway: {
    auction_search: {
      baseUrl: 'api/ad/v1/',
    },
    sign_up: {
      baseUrl: 'api/clientes',
    },
    wallet: {
      route: 'api/wallet',
    },
  },
  production: false,
  pagarme: {
    pk: 'pk_test_xQGL4Glu4EI8a761',
    url: 'https://api.pagar.me/core/v5',
    token_endpoint: 'tokens',
  },
};
