import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { ApiOperations } from 'app/shared/constants/api-operations';
import { User } from 'app/shared/models/user';
import { checkNullOrEmpty } from 'app/shared/functions';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _authenticate = new BehaviorSubject<boolean>(false);
  
  get baseUrl(): string {
		return `${environment.PUBLIC_API_URL}`
	}

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Access-Control-Allow-Methods': '*'
    })
  };

  constructor(
    private http: HttpClient,
    private router: Router) {
  }

  public getIsLogged() {
    return this._authenticate;
  }

  public setIsLogged() {
    this._authenticate.next(true);
  }

  public signIn(user: User): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}${ApiOperations.Auth.SignIn}`, user);
  }

  public isLoggedIn() {
    return !checkNullOrEmpty(localStorage.getItem('accessToken')) && this._authenticate.asObservable();
  }

  public login(accessToken: any) {
    localStorage.setItem('accessToken', accessToken);
    this.refreshSession();
    this.setIsLogged();
  }

  public logout() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('data');
    this._authenticate.next(false);
    this.endSession();
    this.router.navigate(['/login']);
  }

  public getToken() {
    return localStorage.getItem('accessToken');
  }

  public sessionExpired() {
    const sessionExpiresIn = localStorage.getItem('sessionExpiresIn');

    if (sessionExpiresIn) {
      const now = new Date();
      const expiresDate = new Date(Date.parse(sessionExpiresIn.toString()));

      return expiresDate.getTime() - now.getTime() < 0;
    } else {
      return true;
    }
  }

  public refreshSession() {
    const expiresDate = new Date();
    expiresDate.setMinutes(expiresDate.getMinutes() + 60);

    localStorage.setItem('sessionExpiresIn', expiresDate.toString());
  }

  public endSession() {
    localStorage.removeItem('sessionExpiresIn');
  }
}
